.modal {
  max-width: 100%;
  padding: 0;
  margin: 0;
  box-shadow: none;
  background-color: transparent;
}

.overlay {
  background-color: rgba(0, 0, 0, 0.25);
}
