.option {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
  color: var(--onSurface-primary);
  padding: 16px 0;
  cursor: pointer;
}

.option:not(:last-of-type) {
  border-bottom: 1px solid var(--other-divider);
}
