@keyframes enter {
  from {
    right: -20px;
    opacity: 0;
  }

  to {
    right: 0;
    opacity: 1;
  }
}

@keyframes out {
  0% {
    right: 0;
    opacity: 1;
  }

  100% {
    right: -20px;
    opacity: 0;
  }
}

@keyframes hide {
  0% {
  }

  100% {
    height: 0;
    margin-bottom: 0;
  }
}

.wrapper {
  width: 100%;
  border-radius: 14px;
  overflow: hidden;
  margin-bottom: 10px;
}

.wrapper_hidden {
  animation: 0.2s ease-in-out forwards 0.1s hide;
}

.snack {
  position: relative;
  animation: 0.1s ease-in 0s enter;

  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  background: #2a2a2a;

  min-height: 64px;
  padding: 16px 24px;
  border-radius: 14px;

  overflow: hidden;
  color: var(--white);
}

.snack_center {
  align-items: center;
}

.snack_hidden {
  animation: 0.1s ease-in forwards 0s out;
}

.content {
  display: flex;
  color: var(--white);
}

.content_center {
  align-items: center;
}

.icon {
  margin-right: 10px;
  flex-shrink: 0;
}

.description {
  margin-top: 4px;
  font-weight: 400;
}

.actions {
  display: flex;
}

.actions_filled {
  margin-right: 8px;
}

.action {
  padding: 8px 12px;
  border-radius: 8px;
  cursor: pointer;
  color: #02b4d3;
  background-color: transparent;
}

.action:hover {
  background: #02b4d333;
}

.cross {
  position: absolute;
  top: 8px;
  right: 8px;
  cursor: pointer;
}
