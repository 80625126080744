.wrapper {
  padding-top: 72px;
}

.head {
  position: sticky;
  top: 88px;
  display: grid;
  grid-template-columns: 3fr 1fr 1fr 1fr 1fr;
  column-gap: 8px;
  padding: 16px 8px;
  background-color: var(--white);
}

.head > span {
  text-align: end;
}

.loader {
  position: relative;
  min-height: 100px;
}

.tables {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 24px;
}
