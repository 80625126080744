.menu {
  background-color: var(--other-tooltip) !important;
  border-radius: 8px !important;
  width: fit-content;
  margin: 0 !important;
  top: calc(100% + 16px) !important;
  left: -7px;
  min-width: 240px;
  padding: 8px 16px;
  border: none !important;
  box-shadow: none !important;
}

.menu::before {
  content: '';
  position: absolute;
  border-bottom: 7px solid var(--other-tooltip);
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  width: 0;
  height: 0;
  bottom: 100%;
  left: 14px;
  height: 9px;
}
