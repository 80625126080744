.wrapper {
  padding-top: 152px;
}

.card {
  max-width: 520px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.wallet {
  position: relative;
  width: 104px;
  height: 104px;
  border-radius: 50%;
  border-radius: 104px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--secondary-secondary);
}

.title {
  margin-top: 16px;
  text-align: center;
}

.disconnect {
  margin-top: 16px;
}
