.wrapper {
  padding-top: 64px;
  position: relative;
}

.title {
  padding: 0 8px;
}

.cards {
  margin-top: 24px;
  list-style: none;
  display: grid;
  grid-template-columns: repeat(auto-fill, 222px);
  column-gap: 24px;
  padding: 0 8px;
  position: relative;
}

.loader {
  position: relative;
  min-height: 50px;
}
