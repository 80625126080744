* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  padding: 0;
  margin: 0;
}

a {
  cursor: pointer;
  text-decoration: none;
  outline: none;
}

button {
  border: none;
  outline: none;
  cursor: pointer;
}

img {
  display: block;
}

input,
textarea {
  outline: none;
}

.content {
  max-width: calc(1082px + 16px * 2);
  padding: 0 16px;
  width: 100%;
  margin: 0 auto;
}
