.wrapper {
  border-spacing: 0;
  border-radius: 8px;
  overflow: hidden;
  width: 100%;
}

.title_wrapper {
  background-color: var(--secondary-secondary);
  padding: 8px;
}
