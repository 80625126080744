.chip {
  color: var(--onSurface-primary);
  position: relative;
  background-color: transparent;
}

.active::after {
  content: '';
  position: absolute;
  top: calc(100% + 4px);
  left: 0;
  width: 100%;
  height: 1px;
  background-color: var(--onSurface-primary);
}
