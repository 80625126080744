.wrapper {
  padding-top: 72px;
}

.title {
  margin-bottom: 24px;
}

.graph {
  min-height: 370px;
}

.graph > div {
  cursor: pointer;
}
