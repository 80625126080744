.card {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--secondary-secondary);
  border-radius: 8px;
  padding: 16px;
}

.title {
  text-align: center;
}

.icon {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background-color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
}
