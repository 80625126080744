@font-face {
  font-family: 'Neue Haas Grotesk Display Pro';
  src: url('/public/fonts/NeueHaasDisplayMedium.ttf');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Neue Haas Grotesk Display Pro';
  src: url('/public/fonts/NeueHaasDisplayThin.ttf');
  font-weight: 300;
  font-style: normal;
}
