.wrapper {
  background-color: var(--white);
  border-radius: 8px;
  padding: 32px 24px 36px;
}

.title {
  text-align: center;
  margin-bottom: 24px;
}

.wallets {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: -16px 0 0 -16px;
  width: calc(100% + 16px);
}

.card {
  flex: 1 1 auto;
  margin: 16px 0 0 16px;
}
