.card {
  display: flex;
  align-items: center;
}

.card > svg {
  margin-right: 16px;
}

.info {
  display: flex;
  flex-direction: column;
}
