.wrapper {
  padding-top: 72px;
}

.inner {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  margin-top: 32px;
  margin-bottom: 8px;
  text-align: center;
}

.text {
  margin-bottom: 24px;
  text-align: center;
}

.info {
  background-color: var(--secondary-secondary);
  border-radius: 8px;
  padding: 16px;
  max-width: 600px;
  width: 100%;
}

.list {
  list-style-position: inside;
}
