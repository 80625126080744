.wrapper {
  height: 104px;
}

.inner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.link {
  color: var(--black);
}

.link_icon {
  margin-left: 8px;
}
