:root {
  /* Button */
  --primary-primary: #0066be;
  --primary-pressed: #005298;
  --primary-hover: #0072d3;
  --primary-loading: #0072d3;
  --primary-disable: #f7f5f5;

  --secondary-secondary: rgba(0, 0, 0, 0.05);
  --secondary-pressed: rgba(0, 0, 0, 0.2);
  --secondary-hover: rgba(0, 0, 0, 0.1);
  --secondary-disable: #f7f5f5;

  --tetriary-tetriary: #00c2ab;
  --tetriary-pressed: #009b89;
  --tetriary-hover: #01b09b;
  --tetriary-disable: #f7f5f5;

  /* Text */
  --onPrimary-onPrimary: #ffffff;
  --onPrimary-disable: #a9a9a9;

  --onSecondary-onSecondary: #0066be;
  --onSecondary-disable: #a9a9a9;

  --onTetriary-onTetriary: #ffffff;
  --onTetriary-disable: #a9a9a9;

  --onSurface-primary: #000000;
  --onSurface-disable: #c4c4c4;

  /* Background */
  --surface-primary: #ffffff;
  --surface-secondary: #fbfbfb;

  /* Border */
  --border-onSurface: #000000;
  --border-focus: #0066be;
  --border-disable: #a9a9a9;
  --border-cardBorder: #ededed;

  /* Other */
  --other-overlay: rgba(0, 0, 0, 0.25);
  --other-tooltip: #f0f0f0;
  --other-green: #21bf73;
  --other-lightGreen: #ecfcf4;
  --other-darkGreen: #135135;
  --other-orange: #ff7100;
  --other-lightOrange: #fdf3d0;
  --other-darkOrange: #5a2e0b;
  --other-red: #d90429;
  --other-redPressed: #b20322;
  --other-redHover: #ed042d;
  --other-divider: rgba(0, 0, 0, 0.2);

  --white: #ffffff;
  --black: #000000;

  --table-bg: gba(0, 0, 0, 0.03);
}
