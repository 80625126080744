* {
  font-family: 'SF Pro', sans-serif, 'Roboto';
  font-size: 16px;
  line-height: 1.2em;
  font-weight: 400;
}

h1,
.title1 {
  font-family: 'Neue Haas Grotesk Display Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 100%;
}

h2,
.title2 {
  font-family: 'Neue Haas Grotesk Display Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 140%;
}

h3,
.title3 {
  font-family: 'Neue Haas Grotesk Display Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 140%;
}

.large_title {
  font-style: normal;
  font-weight: 300;
  font-size: 50px;
  line-height: 140%;
}

.subheadline {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  letter-spacing: 1px;
}

.body {
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 140%;
}

.headline {
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 140%;
}

.caption {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
}
