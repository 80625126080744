.wrapper {
  display: flex;
  align-items: center;
}

.value {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  background-color: var(--black);
  color: var(--white);
  border-radius: 50%;
}

.title {
  margin-left: 16px;
  display: block;
  white-space: pre-wrap;
}
