@keyframes pulse {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(0.75);
    background-color: #cacaca;
  }
}

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  width: 60px;
}

.pulse-bubble {
  width: 16px;
  height: 16px;
  border-radius: 50%;
}

.pulse-bubble-1 {
  animation: pulse 0.4s ease 0s infinite alternate;
}
.pulse-bubble-2 {
  animation: pulse 0.4s ease 0.2s infinite alternate;
}
.pulse-bubble-3 {
  animation: pulse 0.4s ease 0.4s infinite alternate;
}

.initial {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
