.wrapper {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  height: 88px;
  background-color: var(--white);
  backdrop-filter: blur(4px);
  z-index: 99;
}

.inner {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.brand {
  padding-left: 8px;
  text-align: center;
}

.controls {
  display: flex;
  align-items: center;
}

.info {
  display: flex;
  align-items: center;
  margin-left: 32px;
}

.connect {
  margin-left: 24px;
  position: relative;
  min-width: 165px;
}

.wallet_icon {
  width: 32px;
  height: 32px;
  flex: 0 0 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e6e6e6;
  border-radius: 50%;
  margin-right: 8px;
}

.logout {
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  margin-left: 16px;
}

.wallet_modal {
  max-width: 375px;
  width: 100%;
}

.chain {
  display: flex;
  align-items: center;
  min-width: 144px;
}

.chain > svg {
  margin-right: 8px;
}
