.row {
  display: grid;
  grid-template-columns: 3fr 1fr 1fr 1fr 1fr;
  column-gap: 8px;
  padding: 16px 8px;
}

.row > td:not(:first-of-type) {
  text-align: end;
}
