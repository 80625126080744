.modal {
  max-width: 320px;
  width: 100%;
}

.wrapper {
  background-color: var(--white);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 64px 34px 32px;
}

.icon {
  width: 36px;
  height: 36px;
}

.title {
  margin-top: 24px;
}

.disconnect {
  width: 100%;
  height: 56px;
  background-color: var(--other-red);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 90px;
  color: var(--white);
}
